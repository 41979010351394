@font-face {
    font-family: 'Muli';
    font-style: normal;
    src: url('Muli.woff2') format('woff2-variations');
    font-weight: 1 999;
}

@font-face {
    font-family: 'Muli';
    font-style: italic;
    src: url('Muli-Italic.woff2') format('woff2-variations');
    font-weight: 1 999;
}